import React from "react";

function Footer() {
  return (
    <footer>
      Developed by{" "}
      <a
        href="https://www.instagram.com/perioad/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Perio Alexander
      </a>
    </footer>
  );
}

export default Footer;
